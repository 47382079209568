import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store';
import archivos from '@demre/client-common/src/plugins/archivos';

Vue.use(archivos);

store.dispatch('session/load')
  .then(() => {
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })
