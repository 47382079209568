import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/* common */
const Error404 = () => import('./pages/Error' /* webpackChunkName: 'err404' */);

/* pages */
const Escolaridad = () => import('./pages/Escolaridad' /* webpackChunkName: 'escolaridad' */);

const routes = [
  {
    path: '/',
    name: 'escolaridad',
    component: Escolaridad,
    meta: {
      title: 'Escolaridad'
    }
  },
  {
    path: '*',
    name: 'error',
    component: Error404,
    meta: {
      title: 'Error'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to?.meta?.title ? `${to.meta.title} | Mineduc` : 'Mineduc';
  next();
});

export default router;
