<template>
  <v-app>
    <d-header
      title="Mineduc"
      :username="me"
      :logged="logged"
      :profile="profile"
    />
    <v-main>
      <v-container>
        <router-view />
      </v-container>
      <d-notifier />
      <d-refresher />
    </v-main>
    <d-footer />
    <d-profile />
  </v-app>
</template>



<script>
import Footer from '@demre/client-common/src/components/footer';
import Notifier from '@demre/client-common/src/components/notifier';
import dProfile from '@demre/client-common/src/components/profile';
import dHeader from '@demre/client-common/src/components/header';
import dRefresher from '@demre/client-common/src/components/jwt-refresher';

function getPayload() {
  const token = window.localStorage.getItem('token');
  if(token) {
    const payload = token.split('.')[1];
    const payloadStr = window.atob(payload);
    return JSON.parse(payloadStr);
  }
  return '';
}

export default {
  components: {
    'd-footer' : Footer,
    'd-notifier': Notifier,
    dProfile,
    dHeader,
    dRefresher,
  },
  computed: {
    me : function() {
      const { username } = getPayload();
      const largo = username.length;
      return username.substring(0, largo - 1) + '-' + username.substring(largo - 1);
    },
    logged() {
      return this.$store.getters['session/isLoggedIn']();
    },
    profile() {
      return [
        { title: 'Cambiar contraseña y/o Email', icon: 'account_circle', action: () => this.$store.commit('setProfile', true) },
        { title: 'Salir', icon: 'power_settings_new', action: () => this.$store.dispatch('session/logout') },
      ];
    }
  },
};
</script>
<style scoped>
  .label-text {
    color: #717271
  }
  .label-text_2 {
    color: #717271;
    font-size: 12
  }
</style>

<style>
html {
  overflow-y: auto;
}
</style>
