import Vue from 'vue';
import Vuex from 'vuex';
import session from '@demre/client-common/src/components/jwt-refresher/session';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    session,
  }
});
